@font-face {
  font-family: 'SukhumvitSet';
  font-weight: bold;
  src: url('./SukhumvitSetBold.eot');
  src: url('./SukhumvitSetBold.eot') format('embedded-opentype'),
    url('./SukhumvitSetBold.woff2') format('woff2'), url('./SukhumvitSetBold.woff') format('woff'),
    url('./SukhumvitSetBold.ttf') format('truetype'),
    url('./SukhumvitSetBold.svg#SukhumvitSetBold') format('svg');
}

@font-face {
  font-family: 'SukhumvitSetSemiBold';
  src: url('./SukhumvitSetSemiBold.eot');
  src: url('./SukhumvitSetSemiBold.eot') format('embedded-opentype'),
    url('./SukhumvitSetSemiBold.woff2') format('woff2'),
    url('./SukhumvitSetSemiBold.woff') format('woff'),
    url('./SukhumvitSetSemiBold.ttf') format('truetype'),
    url('./SukhumvitSetSemiBold.svg#SukhumvitSetSemiBold') format('svg');
}

@font-face {
  font-family: 'SukhumvitSetMedium';
  src: url('./SukhumvitSetMedium.eot');
  src: url('./SukhumvitSetMedium.eot') format('embedded-opentype'),
    url('./SukhumvitSetMedium.woff2') format('woff2'),
    url('./SukhumvitSetMedium.woff') format('woff'),
    url('./SukhumvitSetMedium.ttf') format('truetype'),
    url('./SukhumvitSetMedium.svg#SukhumvitSetMedium') format('svg');
}

@font-face {
  font-family: 'SukhumvitSet';
  font-style: normal;
  src: url('./SukhumvitSetText.eot');
  src: url('./SukhumvitSetText.eot') format('embedded-opentype'),
    url('./SukhumvitSetText.woff2') format('woff2'), url('./SukhumvitSetText.woff') format('woff'),
    url('./SukhumvitSetText.ttf') format('truetype'),
    url('./SukhumvitSetText.svg#SukhumvitSetText') format('svg');
}

@font-face {
  font-family: 'SukhumvitSetThin';
  src: url('./SukhumvitSetThin.eot');
  src: url('./SukhumvitSetThin.eot') format('embedded-opentype'),
    url('./SukhumvitSetThin.woff2') format('woff2'), url('./SukhumvitSetThin.woff') format('woff'),
    url('./SukhumvitSetThin.ttf') format('truetype'),
    url('./SukhumvitSetThin.svg#SukhumvitSetThin') format('svg');
}

@font-face {
  font-family: 'SukhumvitSetLight';
  src: url('./SukhumvitSetLight.eot');
  src: url('./SukhumvitSetLight.eot') format('embedded-opentype'),
    url('./SukhumvitSetLight.woff2') format('woff2'), url('./SukhumvitSetLight.woff') format('woff'),
    url('./SukhumvitSetLight.ttf') format('truetype'),
    url('./SukhumvitSetLight.svg#SukhumvitSetLight') format('svg');
}
